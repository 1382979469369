import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login: {
      auth: false,
    },
    lang_en: false,
  },
  mutations: {
    LOGIN: (state, data) => {
      state.login = data;
    },
    LOGOUT: (state) => {
      state.login = {
        auth: false,
      };
    },
    LANGEN: (state) => {
      state.lang_en = !state.lang_en;
    },
    USEREMAIL2: (state, email2) => {
      state.login.email2 = email2;
    },
  },
  actions: {
    logInAction: (context, data) => {
      context.commit("LOGIN", data);
    },
    logOutAction: (context) => {
      context.commit("LOGOUT");
    },
    langEnAction: (context) => {
      context.commit("LANGEN");
    },
    userEmail2Action: (context) => {
      context.commit("USEREMAIL2");
    },
  },
  getters: {
    getLogin: (state) => {
      return state.login;
    },
    getLangEn: (state) => {
      return state.lang_en;
    },
  },
  plugins: [createPersistedState()],
});
